import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/workspace/src/layouts/blogPost.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We are thrilled to introduce the new UI version of the 1hive DAO, which introduces a new proposals feed look and feel with profiles integration.`}</p>
    <p>{`The new proposal feed also includes administrative votes (Decisions) where everyone can participate on the DAO’s configuration decisions, previously these votes were only accessible via the Aragon interface, but this change makes them much harder to miss moving forward. We intend for administrative decisions to continue to be rare, and only used when they are absolutely necessary.`}</p>
    <h2 {...{
      "id": "profiles",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#profiles",
        "aria-label": "profiles permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Profiles`}</h2>
    <p>{`Since we are an open community driven by members we think that having open profiles could be a cool way to visualize members interactions with the system as well as some basic information like profile picture, name, github user and twitter.`}</p>
    <p>{`In order to have this information in a decentralized way we decided to use `}<a parentName="p" {...{
        "href": "https://3box.io/"
      }}>{`3Box`}</a>{`.`}</p>
    <p>{`In a future iteration this is also going to be the place where we can see each member’s skills, in which areas of the community they are contributing and what proposals they are supporting.`}</p>
    <div {...{
      "style": {
        "textAlign": "center",
        "marginBottom": "24px"
      }
    }}>{`
  `}<span parentName="div" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f365deb2d102c9d77144e009df2272a1/3c492/profile.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAACF0lEQVQoz43RW08TQRTA8f2CmvCi+A3EgIESJQYDRBDjA0abQukNC0QTLm1pFaQKUSpETB9M0AewlKsxvWyh2G6X3Z3dv7PLi49ucjIzZ+b8zkxWEaKKZVVwR9MsMz8/Q8A/RiQUIByeICIjNhkgFhonKsdw0E9U7k0GXjAzHcaQNf8airDKclECalRKu8QSs6ycfmPuOE9qf5OF4hazJ19lbDNzuEV8P8fUYY7x4xz9sTHOKgVZq3qGaymWuMASdZnUUMtHvPy4xDwF5pyf5Bf6WEv0scIpWaPImjhix64Q0PI8Nr7wLD3NWfUEh6ZnuJYiWhtY2gaYW5yfpvGvxojoO6T0PdTv25T38jSFJc8YOJbsK2Cx8YOMbBBMRFF/ZWTtpme4lmLXg4jzoLxghGpxlEQ2zoHd4FCvcune24GmYaDpBoYlYeFQMxqo9iWvFsNUD57KA2HPcC1F1CfkYtxLlvaHWV99DbZs2tLRWi1MiekSa2i6/AEmpiUw9Ba26ZBamKJy8AQaIc9wLcVWBxEy+DNCabeTz58k+J/fyps4lUI3XDzyDNdShDp0BdaHqRV78T8fILn0gWQqTSqdYSmTJZFaJpF8K8d3JNNZuefOs4wM96EW78H5FehaijtxaoM0fw+Re3+fgYe36Om+Sa/PjXbudrXRcfsadzqu09XZhk/mfT03vDP9D9pZX/ahyVrn7OqlfwFo52HUUv6NwgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "profile",
            "title": "profile",
            "src": "/static/f365deb2d102c9d77144e009df2272a1/c1b63/profile.png",
            "srcSet": ["/static/f365deb2d102c9d77144e009df2272a1/5a46d/profile.png 300w", "/static/f365deb2d102c9d77144e009df2272a1/0a47e/profile.png 600w", "/static/f365deb2d102c9d77144e009df2272a1/c1b63/profile.png 1200w", "/static/f365deb2d102c9d77144e009df2272a1/3c492/profile.png 1300w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>
    </div>
    <h2 {...{
      "id": "new-feed",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#new-feed",
        "aria-label": "new feed permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`New Feed`}</h2>
    <p>{`With our renovated feed view we are introducing a couple of new functionalities that we want to share with you.`}</p>
    <h4 {...{
      "id": "mixed-feed",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#mixed-feed",
        "aria-label": "mixed feed permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Mixed feed`}</h4>
    <p>{`You will be able to see conviction voting proposals (Proposals for Funding and Suggestions for Signaling) and dandelion voting administrative votes (aka Decisions) in only one dashboard.`}</p>
    <h4 {...{
      "id": "quick-actions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#quick-actions",
        "aria-label": "quick actions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Quick actions`}</h4>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Proposals and Suggestions`}</strong>{`: performing a thumbs up stakes 5% of your balance to the proposal (suggestions accepted about this number). Performing a thumbs down withdraws your entire support from the proposal.`}</li>
      <li parentName="ol"><strong parentName="li">{`Decisions`}</strong>{`: by performing thumbs up you are voting in favour of the proposal and with thumbs down against it.`}</li>
    </ol>
    <div {...{
      "style": {
        "textAlign": "center",
        "marginBottom": "24px"
      }
    }}>{`
  `}<span parentName="div" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c00a8f93047b4c4cd332402d53db8f21/3c492/feed.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAABvUlEQVQoz22QvU9UQRTF99+EGAjEgMKipTGi2drCYGGojVJQkKCJlaWFibILqNEsGMOXRtjweLuzM3c+3rzjmXlgQkJxcicz9/7mnNsS04VIFybXHrTewuHRexwdvMHxr5c4/rlKvbhZ+89xsv8MJ3tP8Wevg9/9DloJaEwPSn0G0MfKyjImJifQbs9i4e4M61zWvfY87i/NY+ma5pravo2FO9NYXJxFy9BVgqpxj8AuHj64ha/f+tC2xHA4grUe3nmUVjD0DrGKCCGpulTku8OoLKCNvXQo2xirjwS+RefxFHZ2vwN1hHNCoHDAYqBKHAz+8nPDO02QR4wVqipQrPlsroA9mHKTwA08WZ7OQE+YMToDRQgxdFgUGdhA7X8lYE0DvtYp8g5Ef4ApXhO4SeAUdpNDVA0oD9Gl91B0pr2FECB0FpIzKsaYZRrgNqx6ByleXQPWdcXILkcLrIqOi/Iczgc4Dgs/CNlZzZ4Azz6VgGK24EYbdLjWAB8R+OUHz4zgXW4OHB7rMc4HZ7DCvXKn6T7BrpScnlbDBPxE4PoNQOSojsOOMQ33Vlxc5Gok7ZXRuQ4RyUlS5L4f4B+TEIW3+cbFwgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "feed",
            "title": "feed",
            "src": "/static/c00a8f93047b4c4cd332402d53db8f21/c1b63/feed.png",
            "srcSet": ["/static/c00a8f93047b4c4cd332402d53db8f21/5a46d/feed.png 300w", "/static/c00a8f93047b4c4cd332402d53db8f21/0a47e/feed.png 600w", "/static/c00a8f93047b4c4cd332402d53db8f21/c1b63/feed.png 1200w", "/static/c00a8f93047b4c4cd332402d53db8f21/3c492/feed.png 1300w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>
    </div>
    <h2 {...{
      "id": "decisions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#decisions",
        "aria-label": "decisions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Decisions`}</h2>
    <p>{`For those new around the community the dandelion votes can be seen as administrative votes to perform actions directly into the DAO like new app installations and app configuration updates.`}</p>
    <p>{`These votes have a two week duration and two days of delay between the vote passes and it can be executed.`}</p>
    <p>{`Keep in mind that if you vote yes on a decision, your entire token balance will be locked for the duration of the vote. This biases the results of administrative decisions towards no action, and ensures that people who support changes are locked in to the outcome of those decisions.`}</p>
    <h2 {...{
      "id": "why-my-proposal-doesnt-appear-anymore",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#why-my-proposal-doesnt-appear-anymore",
        "aria-label": "why my proposal doesnt appear anymore permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Why my proposal doesn’t appear anymore?`}</h2>
    <p>{`As expressed in previous blog posts, we had an issue with the conviction voting app configuration, which prompted an adjustment which disabled execution of funding proposals until we could pass a vote to change the configuration.`}</p>
    <p>{`After this first vote passed we were comfortable re-enabling conviction funding proposals, but also created a second vote to install a fresh instance of the conviction voting app to avoid any odd inconsistenties related to interactions that may have occured prior to the configuration being updated.`}</p>
    <p>{`Since then, the vote has passed and we now have a fresh instance of Conviction Voting installed. Because this is a new installation of the app, all existing proposals have been cleared. They still exist in the old instance of the app, but that app no longer has permission to access the common pool or interact with the token manager.`}</p>
    <p>{`We have seen a lot of proposals not following the `}<a parentName="p" {...{
        "href": "https://forum.1hive.org/new-topic?category=proposals"
      }}>{`appropriate guidelines`}</a>{` or creating proposals without a forum post link, so we also took this opportunity to request more quality and carefully considered proposals. If you had submitted a proposal that was still active, you can go ahead and resubmit your proposals now.`}</p>
    <h2 {...{
      "id": "whats-next",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#whats-next",
        "aria-label": "whats next permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What’s next?`}</h2>
    <p>{`Our next steps towards a more fair community is to give members the ability to challenge proposals through the disputable version of conviction voting plus Celeste, an Aragon Court fork on xDAI.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      